<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="5">
          <s-text weight="medium" color="gray" size="sm">
            Url
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Data used
          </s-text>
        </v-col>
      </v-row>
      <v-row
        v-for="website in websites"
        :key="website.fqdn"
        class="pa-0 ma-0 mb-3 app-card app-card-content"
      >
        <v-col class="d-flex flex-column" cols="5">
           {{ website.fqdn }}
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ formatBytes(website.usage) }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDate } from '@/utils/date'
import { formatBytes } from '@/utils/fmt'
export default {
  name: 'AppWebsites',
  components: {
    loader: Loader,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      websites: 'user/currentUserWebsites',
    }),
  },
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    getHumanReadableDate,
    formatBytes,
    getWebsites () {
      this.isLoading = true
      this.$store
        .dispatch('user/getUserWebsites', this.id)
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  watch: {
    websites: {
      handler () {
        if (this.websites) return
        this.getWebsites()
      },
      immediate: true,
    },
  },
}
</script>
